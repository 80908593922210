import React from "react"

const Hero = () => {
  return (
    <div className="common_banner_section members-zone">
      <div className="container">
        <div className="banner_center_section">
          <h1>COMMB Careers</h1>
          <div className="banner_info"></div>
        </div>
      </div>
    </div>
  )
}

export default Hero